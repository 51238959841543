.App {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

.RocketBoosterImage {
  visibility: visible;
  position: absolute;
  bottom: 0px;
  left: 10%;
}

.RocketImage {
  visibility: visible;
  position: absolute;
  bottom: 120px;
  left: calc(10% - 15px);
}

.RocketImageLaunch {
  visibility: visible;
  position: absolute;
  bottom: 120px;
  left: calc(10% - 15px);
}

.animate-blast-off {
  display: block;
  position: fixed;
  animation: blast-off 1.1s linear;
}

@keyframes blast-off {
  100% {
    bottom: 110vh;
  }
}

.hidden {
  visibility: hidden;
}

/* MOBILE LAYOUT */
@media (max-width: 1023px) {

  .RocketBoosterImage {
    visibility: hidden;
  }

  .RocketImage {
    visibility: hidden;
    position: absolute;
    bottom: 120px;
    left: calc(10% - 15px);
  }

  .RocketImageLaunch {
    visibility: hidden;
    position: absolute;
    bottom: 120px;
    left: calc(10% - 15px);
  }

}
