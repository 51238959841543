@font-face {
    font-family: 'HKGrotesk-Light';
    src: url(./fonts/HKGrotesk-Light.otf);
}

@font-face {
    font-family: 'HKGrotesk-Regular';
    src: url(./fonts/HKGrotesk-Regular.otf);
}

@font-face {
    font-family: 'HKGrotesk-Medium';
    src: url(./fonts/HKGrotesk-Medium.otf);
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,900&display=swap');

html {
  width: 100%;
}

div {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
  font-family: 'HKGrotesk-Regular', 'sans-serif';
}

.Label {
  font-family: 'HKGrotesk-Regular', 'sans-serif';
}

*:focus {
    outline: none;
}
