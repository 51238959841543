.HomePage {
  width: 100%;
  height: 100vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: url('../images/faded-sierra-mountains.png');
  background-position: center;
  background-size: cover;
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 20px;
}

p {
  margin: 0;
  padding: 0;
}

.SSALogo {
  width: 24px;
  height: auto;
  margin-right: 16px;
}

.SSATitle {
  font-size: 1rem;
  color: #707FB4;
  flex: 1;
  padding-top: 2px;
  font-family: 'Montserrat';
  font-weight: 300;
}


.HomePageMainContent {
  width: 100%;
  flex: 1;
}

/* MOBILE LAYOUT */
@media (max-width: 1023px) {

  .SSALogo {
    width: 28px;
    height: auto;
    margin-right: 16px;
  }

  .SSATitle {
    font-size: 18px;
    color: #676f95;
    flex: 1;
  }

}
