.LaunchPage {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #000000;
  background-image: url('../images/star-background.png');
  position: relative;
  overflow: hidden;
}

.LaunchPageMainContent {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LaunchPageGradientImage {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.LaunchPageMainContentTitle {
  margin: 0;
  font-size: 24px;
  color: #FFFFFF;
  margin-top: 130px;
  text-shadow: 0 1px 1px #000000;
  text-align: center;
  z-index: 1;
}

.LaunchPageMainContentText {
  margin: 0;
  font-size: 80px;
  font-family: 'Montserrat', 'sans-serif';
  color: #FFFFFF;
  margin-top: 100px;
  text-align: center;
  font-weight: 900;
  z-index: 1;
}

.LaunchPageDecorationFooter {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.LaunchPageMountainRange {
  position: absolute;
  bottom: -100px;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  z-index: 0;
}

.ContentScroller {
  align-self: baseline;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  cursor: pointer;
  z-index: 1;
  margin-top: 80px;
  padding: 20px;
  transition: background-color .18s ease-out;
  border-radius: 10%;
  background-color: #FFFFFF10;
}

.ContentScroller:hover {
  background-color: #FFFFFF24;
}

.ContentScrollerTitle {
  font-family: 'Montserrat', Helvetica, Arial, san-serif;
  font-weight: 500;
  font-size: 13px;
  color: #FFFFFF;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
}

.ContentScrollerImage {
  width: 48px;
  height: auto;
}

/* MOBILE LAYOUT */
@media (max-width: 1023px) {
  .LaunchPageMountainRange {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    z-index: 0;
  }

  .LaunchPageMainContentTitle {
    font-size: 22px;
  }

  .LaunchPageMainContentText {
    font-size: 64px;
    margin-top: 84px;
  }
}
