.PeoplePage {
  width: 100%;
  background-color: #1C1B1F;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 60px;
  align-items: center;
}

.PeoplePageTextContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}

.PeoplePageTitle {
  color: #FFFFFF;
  font-family: 'Montserrat';
  font-size: 40px;
  font-weight: 900;
  margin: 0;
  margin-bottom: 40px;
  text-align: center;
}

.PeoplePageText {
  color: #FFFFFF;
  font-size: 20px;
  text-align: center;
}

.PeopleList {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
  padding: 0;
  max-width: 800px;
}

.PersonLink {
  text-decoration: none;
}

.Person {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 20px;
}

.PersonImage {
  width: 150px;
  height: 150px;
}

.PersonName {
  color: #FFFFFF;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
  font-weight: 500;
}

.PersonTitle {
  font-weight: 700;
  font-size: 11px;
  color: #ABABAB;
  text-align: center;
  margin-top: 4px;
  text-transform: uppercase;
}

/* MOBILE LAYOUT */
@media (max-width: 1023px) {
  .PeoplePageTitle {
    font-size: 30px;
  }

  .PeoplePageText {
    color: #FFFFFF;
    font-size: 18px;
  }
}
